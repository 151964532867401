import request from '@/utils/request'

// 查询消息模板列表
export function listTemplate (query) {
  return request({
    url: '/message/template/list',
    method: 'get',
    params: query
  })
}

// 查询消息模板详细
export function getTemplate (templateId) {
  return request({
    url: '/message/template/' + templateId,
    method: 'get'
  })
}
// 查询消息模板详细
export function getOneTemplateByTypeCoe (typeCode) {
  return request({
    url: '/message/template/getInfoByMsgTypeCode/' + typeCode,
    method: 'get'
  })
}

// 新增消息模板
export function addTemplate (data) {
  return request({
    url: '/message/template',
    method: 'post',
    data: data
  })
}

// 修改消息模板
export function updateTemplate (data) {
  return request({
    url: '/message/template',
    method: 'put',
    data: data
  })
}

// 删除消息模板
export function delTemplate (templateId) {
  return request({
    url: '/message/template/' + templateId,
    method: 'delete'
  })
}

// 导出消息模板
export function exportTemplate (query) {
  return request({
    url: '/message/template/export',
    method: 'get',
    params: query
  })
}

// 通过渠道类型获取消息内容类型
export function queryMessageTypesByChannelType (channelType) {
  if (!channelType) return Promise.resolve([])
  return request({
    url: '/message/template/queryMessageTypesByChannelType/' + channelType,
    method: 'get'
  })
}

// 获取所有消息内容类型
export function queryMessageTypes () {
  return request({
    url: '/message/template/queryMessageTypes',
    method: 'get'
  })
}
